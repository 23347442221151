import lastphoto from "../../assets/lastphoto.svg";
import buybtn from "../../assets/buybtn.svg";
import React from "react";
import mainImage from "../../assets/cameras/main-image-short.webp";
import azviz from "../../assets/cameras/azviz-camera-web.webp";
import casa from "../../assets/cameras/casa-camera-web.webp";
import eufy from "../../assets/cameras/eufy-camera-web.webp";
import swipeButtonr from "../../assets/swipebtR.svg";
import swipeButtonl from "../../assets/swipebtL.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper/modules";
// import RatingBar from "../../Components/Daynamic_Components/RatingBar";
import DropdownMenu from "../../Components/Daynamic_Components/DropdownMenu";
import FeatureDropdown from "../Daynamic_Components/FeatureDropdown";



export default function Product1({ }: // videos = [],
  any) {
  // const videos = [
  //   {
  //     id: 1,
  //     title: "تركيب الكاميرا",
  //     description: "دليل خطوة بخطوة لتركيب الكاميرا.",
  //     img: expvideo1,
  //     description2: "طريقة استخدام جد بسيطة ",
  //     description3: "24 يوليو 2024",
  //   },
  //   {
  //     id: 2,
  //     title: "استخدام الكاميرا",
  //     description: "كيفية استخدام الكاميرا وإعداداتها.",
  //     img: expvideo2,
  //     description2: "طريقة استخدام جد بسيطة ",
  //     description3: "24 يوليو 2024",
  //   },
  //   {
  //     id: 3,
  //     title: "استخدام الكاميرا",
  //     description: "كيفية استخدام الكاميرا وإعداداتها.",
  //     img: expvideo3,
  //     description2: "طريقة استخدام جد بسيطة ",
  //     description3: "24 يوليو 2024",
  //   },

  //   {
  //     id: 4,
  //     title: "استخدام الكاميرا",
  //     description: "كيفية استخدام الكاميرا وإعداداتها.",
  //     img: expvideo1,
  //     description2: "طريقة استخدام جد بسيطة ",
  //     description3: "24 يوليو 2024",
  //   },
  // ];
  // const specifications = [
  //   {
  //     title: "دقة التصوير",
  //     description: "كاميرا بدقة 1080p مع خاصية الرؤية الليلية.",
  //   },
  //   {
  //     title: "دقة التصوير",
  //     description: "كاميرا بدقة 1080p مع خاصية الرؤية الليلية.",
  //   },
  //   {
  //     title: "دقة التصوير",
  //     description: "كاميرا بدقة 1080p مع خاصية الرؤية الليلية.",
  //     image: camcap1,
  //   },
  //   {
  //     title: "زاوية العرض",
  //     description: "زاوية عرض واسعة 120 درجة لتغطية كاملة.",
  //     image: camcap2,
  //   },
  //   {
  //     title: "زاوية العرض",
  //     description: "زاوية عرض واسعة 120 درجة لتغطية كاملة.",
  //     image: camcap2,
  //   },
  // ];
  // const specifications2 = [
  //   {
  //     title: "دقة التصوير",
  //     description: "كاميرا بدقة 1080p مع خاصية الرؤية الليلية.",
  //   },
  //   {
  //     title: "دقة التصوير",
  //     description: "كاميرا بدقة 1080p مع خاصية الرؤية الليلية.",
  //   },
  //   {
  //     title: "دقة التصوير",
  //     description: "كاميرا بدقة 1080p مع خاصية الرؤية الليلية.",
  //     image: camcap1,
  //   },
  //   {
  //     title: "زاوية العرض",
  //     description: "زاوية عرض واسعة 120 درجة لتغطية كاملة.",
  //     image: camcap2,
  //   },
  //   {
  //     title: "زاوية العرض",
  //     description: "زاوية عرض واسعة 120 درجة لتغطية كاملة.",
  //     image: camcap2,
  //   },
  // ];
  const cameras: any = [
    // swipimg1,
    // swipimg2,
    // swipimg3,
    // swipimg3,
    // swipimg3,
    // swipimg3,
  ];
  const renderExtraImages = () => {
    if (cameras.length > 3) {
      return (
        <div className="relative mt-[18px] w-[80%] mx-auto">
          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            navigation={{
              nextEl: ".custom-swiper-button-next",
              prevEl: ".custom-swiper-button-prev",
            }}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="flex justify-center items-center"
          >
            {cameras.map((img: any, index: any) => (
              <SwiperSlide
                key={index}
                className="w-[228px] h-[195px] rounded-[12px]  flex items-center justify-center"
              >
                <img src={img} alt={`cameras ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="custom-swiper-button-prev cursor-pointer absolute z-10 left-[-25px] top-1/2 transform -translate-y-1/2">
            <img
              src={swipeButtonl}
              alt="Previous"
              className="md:w-auto md:h-auto w-[28px] h-[28px]"
            />
          </div>
          <div className="custom-swiper-button-next cursor-pointer absolute z-10 right-[-25px] top-1/2 transform -translate-y-1/2">
            <img
              src={swipeButtonr}
              alt="Next"
              className="md:w-auto md:h-auto w-[28px] h-[28px]"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center gap-6 mt-12">
          {cameras.map((img: any, index: any) => (
            <img
              key={index}
              src={img}
              alt={`Extra Camera Image ${index + 1}`}
              className="w-[228px] h-[195px] rounded-[12px] "
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="mt-[150px] md:w-[85%] w-[90%] mx-auto mb-28 overflow-hidden">
      <div className="mb-[24px]">
        <div className="md:w-[80%] w-[100%] mx-auto rounded-[12px]  flex items-center justify-center">
          <img src={mainImage} alt="Main Camera" />
        </div>
        {/* {cameras.length > 0 && renderExtraImages()} */}
      </div>


      <div className="w-full items-center justify-center my-8">
        <h1 className="text-[#3D5B96] md:text-[42px] text-[25px] mb-[80px] font-bold w-full text-center">
          أنواع كاميرات المراقبة مع المميزات والسلبيات وثلاث منتجات بسعر جيد
        </h1>
      </div>


      <div className="mb-[24px]">
        {/* <DropdownMenu /> */}

        <FeatureDropdown
          title="المقدمة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">إن كاميرات المراقبة تعد أحد أهم الأجهزة في هذا العصر والتي يجب أن تتوفر في منزلك، وذلك بسبب مساهمتها الفعالة في تقليل الجرائم وتعزيز الأمان، من خلال توفير تسجيلات مرئية، تساعد كاميرات المراقبة في التعرف على المتسللين وتقديم الأدلة اللازمة للسلطات في حال حدوث أي حادثة أو مشكلة، كما أنها تمنحك راحة البال بمعرفة من يدخل منزلك ومتى، مما يجعلها استثمارًا ضروريًا لكل منزل، وفي هذا المقال سنذكر أنواع كاميرات المراقبة وميزاتها، وأسعارها.</p>
          </div>
        </FeatureDropdown>


        <FeatureDropdown
          title="أنواع كاميرات المراقبة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] m-3">عندما يتعلق الأمر بحماية منزلك، فإن اختيار النوع المناسب من كاميرات المراقبة يمكن أن يحدث فرقًا كبيرًا، وسيتم ذكر نوعين من أنواع كاميرات المراقبة المتاحة في السوق:</p>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="1. كاميرات مراقبة سلكية"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">هذا النوع من كاميرات المراقبة يحتاج إلى أسلاك للتشغيل ولنقل الفيديو، وهو مناسب جدا لمن يريد التركيب لمرة واحدة مع عدم التفكير بالطاقة اللازمة للتشغيل، بالإضافة إلى أنها ذات كفاءة عالية في نقل البيانات باستقرار عالي من دون تداخل مع ترددات الأجهزة.</p>
              </div>

              <div className="w-[85%]">
                <FeatureDropdown
                  title="الإيجابيات"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <div className="w-screen">
                    <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                      <li>استقرار نقل الفيديو، فهي لا تتأثر بترددات الأجهزة الأخرى.</li>
                      <li>توفر الطاقة بسبب اتصالها الدائم بالكهرباء.</li>
                    </ul>
                  </div>
                </FeatureDropdown>
              </div>

              <div className="w-[85%]">
                <FeatureDropdown
                  title="السلبيات"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <div className="w-screen">
                    <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                      <li>صعوبة التركيب، قد تحتاج إلى فني للتركيب.</li>
                      <li>كثرة الأسلاك والحاجة إلى التمديد، وقد تحتاج إلى حفر الجدران.</li>
                    </ul>
                  </div>
                </FeatureDropdown>
              </div>

            </FeatureDropdown>
          </div>


          <div className="w-[85%]">
            <FeatureDropdown
              title="2. كاميرات مراقبة لا سلكية:"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">هي كاميرات لا تحتاج إلى أي أسلاك لتشغيلها وتستخدم الواي فاي لنقل البيانات، مما يوفر عليك وقت التركيب وأيضا سهولة تغيير مكانها عند الحاجة، وهذا النوع مناسب جدا لمن يريد الاستخدام بسرعة ومن يحتاج إلى تغيير مكان الكاميرا بشكل متكرر، ولكن من المهم معرفة أن كاميرات المراقبة اللاسلكية تعمل باستخدام:</p>
                <ol className="list-decimal pr-5 text-[#3D5B96] w-[85%]">
                  <li>البطارية</li>
                  <li>الشحن (قد تحتاج إلى التوصيل بالكهرباء)</li>
                  <li>الطاقة الشمسية</li>
                </ol>
              </div>

              <div className="w-[85%]">
                <FeatureDropdown
                  title="الإيجابيات"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <div className="w-screen">
                    <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                      <li>سهولة الاستخدام، فهي لا تحتاج إلى أسلاك للعمل.</li>
                      <li>المرونة في تغيير المكان، بسبب أنها غير متصلة بالأسلاك.</li>
                    </ul>
                  </div>
                </FeatureDropdown>
              </div>

              <div className="w-[85%]">
                <FeatureDropdown
                  title="السلبيات"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <div className="w-screen">
                    <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                      <li>قد تتعرض الكاميرات المتصلة بالواي فاي للاختراق، لذا من المهم عدم وضعها في الأماكن الخاصة.</li>
                      <li>التأثر من تداخلات إشارة الواي فاي مع الأجهزة الأخرى.</li>
                      <li>الحاجة إلى واي فاي جيد لنقل البيانات.</li>
                    </ul>
                  </div>
                </FeatureDropdown>
              </div>

            </FeatureDropdown>
          </div>
        </FeatureDropdown>


        <FeatureDropdown
          title="العوامل المحددة للاختيار"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
              <li>التركيب</li>
              <li>المرونة في التنقل</li>
              <li>استقرار النقل</li>
              <li>الطاقة</li>
              <li>السعر</li>
            </ul>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="التركيب"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  إذا كنت تريد استخدامًا سهلًا من دون عناء التركيب فإن كاميرات المراقبة اللاسلكية هي الاختيار المناسب لك، وهذا بسبب عدم حاجتها إلى أسلاك أو حفر في الجدران للتركيب.
                </p>
              </div>
            </FeatureDropdown>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="المرونة في التنقل"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  بما أن كاميرات المراقبة اللاسلكية لا تحتاج إلى أسلاك فهذا يجعلها مناسبة جدا لمن يحتاج نقل مكان الكاميرا، بالأخص إذا كان هذا الشيء يحدث بشكل متكرر بعكس الكاميرات السلكية الثابتة في مكان محدد والتي يصعب نقلها.
                </p>
              </div>
            </FeatureDropdown>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="استقرار النقل"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  هنا تتميز كاميرات المراقبة السلكية، هذا بسبب أنها لا تتأثر بترددات الأجهزة المجاورة، فهي تعمل على نقل البيانات من خلال الأسلاك مما يحافظ على جودة واستقرار النقل بعكس الكاميرات اللاسلكية التي تنقل الفيديو باستخدام الواي فاي والتي قد تتأثر مع ترددات الأجهزة الأخرى.
                </p>
              </div>
            </FeatureDropdown>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="الطاقة"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  كاميرات المراقبة السلكية متصلة دائما بالكهرباء من خلال الأسلاك، وهذا يجعلها تعمل دائما من دون توقف، وهي مناسبة للذين يريدون كاميرات تعمل طوال اليوم من دون أي خوف من انتهاء الطاقة، بخلاف كاميرات المراقبة اللاسلكية التي قد تتوفر بعدة طرق لتوفير الطاقة، ولكن قد لا تكون متصلة دائما بالكهرباء وإن كانت مدة العمل قبل انتهاء الطاقة جيدة.
                </p>
              </div>
            </FeatureDropdown>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="السعر"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  تتراوح أسعار كاميرات المراقبة اللاسلكية تقريبا بين 80-600 ريال، وهي تعد الأكثر انتشارا عند الشراء من الإنترنت، كما يمكن الذهاب إلى المحلات لرؤية أسعار الكاميرات وشراء الكاميرا المناسبة لك.
                </p>
              </div>
            </FeatureDropdown>
          </div>

        </FeatureDropdown>


        <FeatureDropdown
          title="الخاتمة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              تعد كاميرات المراقبة أحد أهم المنتجات التي تستخدم لحماية الأماكن، وذلك بسبب قدرتها على تصوير الداخلين والخارجين والأشخاص الذين تواجدوا في المكان، وهذا يساهم في تقليل المجرمين وتسهيل القبض عليهم، لذلك إذا أردت حماية منزلك عليك أن تشتري واحدة على الأقل من كاميرات المراقبة المناسبة لك
            </p>

            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              نأمل أن تكون هذه المعلومات كافية ومفيدة بالنسبة لك، وإذا لم تجد إجابة كافية لأسئلتك أو شعرت أن هناك معلومات ناقصة تحتاجها فيفضل أن تزيد من معلوماتك عن كاميرات المراقبة من خلال البحث عن مصادر أخرى.
            </p>
          </div>
        </FeatureDropdown>



        <FeatureDropdown
          title="الأسئلة الشائعة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-[85%]">
            <FeatureDropdown
              title="كيف أختار كاميرا المراقبة المناسبة؟"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  يمكنك فعل ذلك من خلال مراجعة هذه العوامل:
                </p>

                <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                  <li>التركيب</li>
                  <li>المرونة في التنقل</li>
                  <li>استقرار النقل</li>
                  <li>الطاقة</li>
                  <li>السعر</li>
                </ul>
              </div>
            </FeatureDropdown>

            <FeatureDropdown
              title="هل توجد كاميرات مراقبة بدون سلك؟"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  نعم، تسمى كاميرات لا سلكية وتعمل من خلال الطاقة الشمسية أو البطارية أو الشحن (قد تحتاج إلى التوصيل بالكهرباء).
                </p>
              </div>
            </FeatureDropdown>

            <FeatureDropdown
              title="هل تعمل كاميرات المراقبة إذا حدث انقطاع في الكهرباء؟"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  إذا كانت كاميرات المراقبة لا سلكية فالإجابة نعم طالما توفرت الطاقة، أما إذا كانت سلكية فإنها تنقطع، ولكن هناك بعض الكاميرات السلكية التي تعمل بعد انقطاع الطاقة وذلك باستخدام مصدر ثانوي للطاقة.
                </p>
              </div>
            </FeatureDropdown>

          </div>
        </FeatureDropdown>


        <FeatureDropdown
          title="المنتجات"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              سيتم ذكر ثلاث منتجات من كاميرات المراقبة اللاسلكية الموجودة في أمازون مع الروابط التسويقية، وسيتم الاعتماد على التقييمات وكثرة المبيعات للتأكد جودة المنتجات، وهذه الأنواع هي:
            </p>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="كاميرا ازفيز"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-[85%]">
                <FeatureDropdown
                  title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <img src={azviz} alt="" />
                </FeatureDropdown>

                <div className="w-screen">
                  <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                    <li>اسم الطراز: C6N ezviz</li>
                    <li>التقييم: 4.3</li>
                    <li>عدد التقييمات: 7,017 (7017)</li>
                    <li>ميزات: الرؤية الليلية</li>
                    <li>التوافق: متوافق مع الهواتف الذكية والحواسيب اللوحية</li>
                    <li>مصدر الطاقة: محول</li>
                    <li>نوع الاتصال: شبكة الواي فاي (wifi)</li>
                    <li>بلد المنشأ: الصين</li>
                    <li>السعر: 89 ريال</li>
                    <li>ملاحظة: تعد أحد أكثر المنتجات مبيعا وأكثر كاميرا مباعة في أمازون</li>
                    <li>شراء:</li>
                  </ul>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/4grVO7E">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>


            <FeatureDropdown
              title="كاميرا تي بي - لينك"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-[85%]">
                <FeatureDropdown
                  title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <img src={casa} alt="" />
                </FeatureDropdown>

                <div className="w-screen">
                  <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                    <li>اسم الطراز: باي تي بي لينك اندور</li>
                    <li>التقييم: 4.4</li>
                    <li>عدد التقييمات: 21,734 (21834)</li>
                    <li>ميزات: الرؤية الليلية، مستشعر الحركة</li>
                    <li>التوافق: متوافق مع الهواتف الذكية</li>
                    <li>مصدر الطاقة: البطارية</li>
                    <li>نوع الاتصال: شبكة الواي فاي (wifi)</li>
                    <li>السعر: 151 ريال</li>
                    <li>شراء:</li>
                  </ul>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/4gpXFtz">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>

            <FeatureDropdown
              title="كاميرا سولو كام"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-[85%]">
                <FeatureDropdown
                  title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <img src={eufy} alt="" />
                </FeatureDropdown>

                <div className="w-screen">
                  <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                    <li>اسم الطراز: يوفي S220 سولو كام</li>
                    <li>التقييم: 4.3</li>
                    <li>عدد التقييمات: 1,634 (1634)</li>
                    <li>ميزات: تسجيل محلي</li>
                    <li>التوافق: متوافق مع الهواتف الذكية</li>
                    <li>مصدر الطاقة: الطاقة الشمسية</li>
                    <li>نوع الاتصال: شبكة الواي فاي (wifi)</li>
                    <li>السعر: 329 ريال</li>
                    <li>شراء:</li>
                  </ul>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/4erSqYz">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>
          </div>
        </FeatureDropdown>

      </div>


    </div>
  );
}
