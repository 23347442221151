import React, { useState, useEffect, useRef, ReactNode } from 'react';

interface FeatureDropdownProps {
  title: string;
  children: ReactNode;
  customStyles?: string;
}

const FeatureDropdown: React.FC<FeatureDropdownProps> = ({ title, children, customStyles }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasOpenedAutomatically, setHasOpenedAutomatically] = useState(false); // Track auto-open
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle dropdown manually
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Automatically open dropdown when in view, but only once
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Automatically open only if it hasn't been opened automatically before
          if (entry.isIntersecting && !hasOpenedAutomatically) {
            setIsOpen(true);
            setHasOpenedAutomatically(true); // Mark as opened automatically
          }
        });
      },
      {
        threshold: 0, // Trigger when any part of the component is visible
      }
    );

    if (dropdownRef.current) {
      observer.observe(dropdownRef.current);
    }

    return () => {
      if (dropdownRef.current) {
        observer.unobserve(dropdownRef.current);
      }
    };
  }, [hasOpenedAutomatically]);

  // Filter out child dropdowns and other elements separately
  const dropdowns = React.Children.toArray(children).filter(
    (child: any) => React.isValidElement(child) && child.type === FeatureDropdown
  );
  const otherChildren = React.Children.toArray(children).filter(
    (child: any) => !React.isValidElement(child) || child.type !== FeatureDropdown
  );

  return (
    <div ref={dropdownRef} className="my-2">
      <button
        onClick={toggleDropdown}
        className={`w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none ${customStyles}`}
      >
        <span>{title}</span>
        <svg
          className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
          width="20"
          height="20"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.29a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div>
          <div className="w-full">
            {otherChildren.map((child, index) => (
              <div key={index} className="w-full">
                {child}
              </div>
            ))}
          </div>
          <div className="mt-4">
            {dropdowns.map((dropdown, index) => (
              <div key={index}>
                {dropdown}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureDropdown;