import React from 'react'
import Hero from '../Components/HomeComponent/Hero'
import SwipperComponent from '../Components/HomeComponent/SwipperComponent'
import Products from '../Components/HomeComponent/Products'
import Visitors from '../Components/HomeComponent/Visitors'
import Contacts from '../Components/HomeComponent/Contacts'
export default function Home() {
  return (
    <>
      <Hero/>
      <SwipperComponent/>
      {/* <Products/> */}
      <Visitors/>
      <Contacts/>
    </>
  )
}
