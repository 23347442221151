import React from 'react';
import './App.css';
import NavBar from './Components/NavBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import Home from './Views/Home';
import Infos from './Views/Infos';
import About from './Views/About';
import Sections from './Views/Sections';
import { articles } from './data/sectionData';


function App() {
  return (
    <Router>
    <div className="App">
<NavBar/>
<Routes>
  <Route path='/' Component={Home}/>
  <Route path='/contactInformation' Component={Infos}/>
  <Route path='/about' Component={About}/>
  <Route path='/sec' Component={Sections}/>
  {articles.map((article:any) => (
            <Route key={article.id} path={`/sec/${article.id}`} element={<article.component />} />
          ))}

</Routes>

<Footer/>
    </div>
    </Router>

  );
}

export default App;
