import React from 'react';
import { Link } from 'react-router-dom';

interface CardProps {
  img: string;
  title: string;
  description: string;
  id?: number
}

const Articles: React.FC<CardProps> = ({ img, title, description, id }) => {
  return (
    <div className="card">
      <div className=' h-[220px] rounded-[12px] overflow-hidden w-full '>

        <img src={img} alt={title} className="mb-2 h-full w-full object-cover" />
      </div>
      <div>
        <h2 className="text-[#3D5B96] text-[20px] semiBold mb-2">{title}</h2>
        <p className="text-[#3D5B96] text-[14px] md:text-[16px] md:w-[85%] w-[90%]">{description}</p>
        <div className=' py-3  border-solid border-[1px] border-[#3D5B96] rounded-lg w-[117px] items-center justify-center flex mt-2 cursor-pointer '>
          <Link to={`/sec/${id}`}>
            <h2 className='text-[#3D5B96] normal '>
              التفاصيل
            </h2>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Articles;