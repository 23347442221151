// data.ts

import Product1 from '../Components/ProdoctComponents/Product1'
import cameras from '../assets/cameras/main-image-short.webp'

import Product2 from '../Components/ProdoctComponents/Product2'
import laptopStands from '../assets/laptop stands/main-image.webp'

import Product3 from '../Components/ProdoctComponents/Product3'
import soundCanselling from '../assets/sound canselling/main-image.webp'

export const articles = [

  { id:1,
    img: cameras,
    title: 'كاميرات المراقبة',
    description: 'أنواع كاميرات المراقبة مع المميزات والسلبيات وثلاث منتجات بسعر جيد',
     category: 'إلكترونيات',
     component:Product1
  },
  { id:2,
    img: laptopStands,
    title: 'حاملات اللابتوب',
    description: 'حاملات اللابتوب وأهميتها وأسعارها مع بعض المنتجات للشراء',
     category: 'إكسسوارات اللابتوب',
     component:Product2
  },
  { id:3,
    img: soundCanselling,
    title: 'عازل الصوت',
    description: 'شرح لكيفية عزل الأصوات والمنتجات التي تساهم في ذلك',
     category: 'عزل الصوت',
     component:Product3
  },


];
