import React from 'react';
import { visitorsData } from '../../data/visitorsData'; // Import the data

export default function Visitors() {
  return (
    <div className='mt-5 md:w-[75%] w-[85%] mx-auto mb-28'>
      <div className='w-full bg-[#3D5B96] py-[64px] rounded-[40px] flex flex-wrap justify-between px-[72px] gap-y-8'>
        {visitorsData.map((visitor:any, index:any) => (
          <div key={index} className='flex items-center gap-[24px] md:w-[226px] w-[200px]'>
            <div className='bg-white w-[70px] h-[74px] rounded-[20px] flex items-center justify-center'>
              <img src={visitor.image} alt={visitor.title} />
            </div>
            <div>
              <h2 className='text-white text-[24px]'>{visitor.howMany}</h2>
              <p className='text-white'>{visitor.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
