import React from 'react'
import { FaRegEnvelope } from "react-icons/fa";
import phone from '../../assets/bigPhone.svg'
// import phone from '../../assets/bigPhone.svg'
export default function Contacts() {
  return (
    <div className='mt-5 md:w-[70%] w-[85%] mx-auto mb-28 '>
      <div className=' w-[100%] bg-[#FFF9BF] py-[32.5px] items-center rounded-[32px] flex justify-between md:flex-row flex-col-reverse  px-[48px] '>
        <div className=' md:w-[50%] flex flex-col'>
          <h2 className='text-[#3D5B96] text-[32px] semiBold mb-2'>
            لماذا نحن
          </h2>
          {/* <h2 className='text-[#3D5B96] text-[32px] semiBold mb-2'>
تعرف على فكرة موقعنا
</h2> */}
          {/* تغيير */}
          <p className='text-[#3D5B96] text-[16px]'>
            لأننا نركز في موقعنا على تقديم معلومات شاملة حول المنتجات التي تجعل حياتك أبسط وأكثر سهولة، ونحرص أيضا على جمع المعلومات من مصادر متعددة لضمان أن يكون اختيارك للمنتج مناسبًا لاحتياجاتك قبل الشراء، كما نولي اهتمامًا خاصًا بالتقييمات وعدد المشترين لضمان أن المنتج الذي تختاره سيكون مُرضيًا لك تمامًا.
          </p>
          {/* <h2 className='text-[#3D5B96] text-[32px] semiBold mb-2'>
اشترك معنا
</h2> */}
          {/* <div className=' md:flex-row flex-col flex gap-2 items-center'>
        <div className=' border-[0.5px] border-solid border-[#3D5B96] rounded-[8px] py-2 px-1 flex items-center gap-1 w-[250px]'>
        <FaRegEnvelope size={24} color='#3D5B96'/>
        <input type="text" placeholder='ادخل بريدك الالكتروني' className=' focus:outline-none border-none bg-transparent' />
        </div>
        <div className='bg-[#3D5B96] py-2 px-[24px] rounded-[8px] '>
   <a href="/" className=' text-white'>
<h2>
اشتراك
</h2>

   </a>
</div>

    </div> */}
        </div>
        <div>
          <img src={phone} alt="" />
        </div>
      </div>
    </div>
  )
}
