// ArticleCard.tsx
import React from 'react';
import { Link } from 'react-router-dom';

interface ArticleCardProps {
  img: string;
  title: string;
  description: string;
  id?:number
}

const ArticleCard: React.FC<ArticleCardProps> = ({ img, title, description, id }) => {
  return (
    <div className="flex flex-col md:flex-row items-start overflow-hidden shadow-sm w-full h-auto md:h-[180px]"> 
      <div className="w-full md:w-[180px] h-[180px] md:h-full flex-shrink-0 overflow-hidden rounded-[12px]">
        <img className="w-full h-full object-cover" src={img} alt={title} /> 
      </div>

      <div className="flex flex-col justify-between px-4 py-2 w-full">
        <h3 className="font-bold text-[16px] md:text-[20px] text-[#3D5B96] mb-2">{title}</h3>
        <p className="text-[#3D5B96] text-[14px] md:text-[16px] overflow-hidden text-ellipsis line-clamp-3 mb-2">
          {description}
        </p>
        <Link to={`/sec/${id}`}>
          <button className="text-[#3D5B96] text-[12px] md:text-[16px] rounded-[8px] w-full max-w-[117px] mt-[8px] px-[10px] py-[8px] border border-[#3D5B96] font-bold">
            التفاصيل
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ArticleCard;
