import lastphoto from "../../assets/lastphoto.svg";
import buybtn from "../../assets/buybtn.svg";
import React from "react";
import mainImage from "../../assets/laptop stands/main-image.webp";
import xpower from "../../assets/laptop stands/xpower.webp";
import sz1 from "../../assets/laptop stands/sz-1.webp";
import sonddance from "../../assets/laptop stands/sonddance.webp";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import FeatureDropdown from "../Daynamic_Components/FeatureDropdown";



export default function Product1({ }: // videos = [],
  any) {

  return (
    <div className="mt-[150px] md:w-[85%] w-[90%] mx-auto mb-28 overflow-hidden">
      <div className="mb-[24px]">
        <div className="md:w-[80%] w-[100%] mx-auto rounded-[12px]  flex items-center justify-center">
          <img src={mainImage} alt="Main Camera" />
        </div>
      </div>


      <div className="w-full items-center justify-center my-8">
        <h1 className="text-[#3D5B96] md:text-[42px] text-[25px] mb-[80px] font-bold w-full text-center">
          حاملات اللابتوب
        </h1>
      </div>


      <div className="mb-[24px]">

        <FeatureDropdown
          title="المقدمة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              تعد حاملات الأجهزة بشكل عام وحاملات اللابتوب بشكل خاص أدوات ضرورية للأشخاص الذين يستخدمون أجهزتهم لفترات طويلة، إذ تُساهم بشكل كبير في تحسين وضعية الجلوس أو الاستلقاء، مما يقلل من إجهاد الظهر، نظرًا لأن وضعيات الجلوس المريحة تختلف من شخص لآخر، فإن الحصول على حامل لابتوب قابل للتعديل يمكن أن يتيح لك الحرية في اختيار الوضعية المثالية دون إجبار نفسك على الجلوس بطريقة قد تؤدي إلى آلام الظهر.
            </p>
          </div>
        </FeatureDropdown>


        <FeatureDropdown
          title="أهمية حاملات اللابتوب"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              حامل اللابتوب هو منتج يشبه الطاولة، مصمم خصيصًا لحمل اللابتوب، ويتيح لك ضبط وضعية الجهاز سواء عموديًا أو أفقيًا، هذه المرونة تساعدك على اختيار الوضعية المثالية للجلوس، مما يساهم بشكل كبير في تجنب آلام الظهر، كما أنها قد تساهم في تحسين أداء اللابتوب بسبب وجود مكان للتهوية.
            </p>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="الإيجابيات"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                  <li>تسهيل استخدام اللابتوب لفترات طويلة.</li>
                  <li>إمكانية ضبط وضعية اللابتوب بما يناسب الجلوس.</li>
                </ul>
              </div>
            </FeatureDropdown>

            <FeatureDropdown
              title="السلبيات"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                  <li>قد لا توفر بعض الحاملات قابلية كافية للطي أو تعديل الزوايا.</li>
                </ul>
              </div>
            </FeatureDropdown>
          </div>
        </FeatureDropdown>


        <FeatureDropdown
          title="العوامل المحددة للاختيار"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
              <li>كثرة الاستعمال</li>
              <li>النوع</li>
              <li>السعر</li>
            </ul>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="كثرة الاستعمال"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  إذا كنت تستخدم اللابتوب لساعات طويلة، فإن شراء حامل قد يكون ضرورة، لأن الجلوس بطريقة غير مريحة قد يتسبب في آلام مزمنة في الظهر، وقد ترغب أحيانًا في الاستلقاء ولا تستطيع بسبب عدم وجود حامل يناسب تلك الوضعية الأفقية.
                </p>
              </div>
            </FeatureDropdown>

            <FeatureDropdown
              title="النوع"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  كما تم ذكره، تختلف مواصفات حاملات اللابتوب من حيث الجودة، مثل: نوع المادة، القدرة على الطي، الحجم، والميزات الإضافية، لذا من الضروري عند الشراء اختيار الحامل الذي يناسب احتياجاتك اليومية لضمان الراحة أثناء استخدام اللابتوب.
                </p>
              </div>
            </FeatureDropdown>

            <FeatureDropdown
              title="السعر"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  تتفاوت أسعار حاملات اللابتوب بين 20 - 200 ريال، وذلك يعتمد على عدة عوامل مثل نوع المادة المستخدمة، قابلية الطي، الحجم، والميزات الإضافية، قد تجد أن بعض الحوامل ذات المواصفات العالية باهظة الثمن، ولكن من الممكن العثور على خيارات مناسبة تتماشى مع احتياجاتك وميزانيتك.
                </p>
              </div>
            </FeatureDropdown>


          </div>
        </FeatureDropdown>


        <FeatureDropdown
          title="الخاتمة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              يُعد حامل اللابتوب أداة أساسية لكل من يستخدم الجهاز لفترات طويلة، حيث يُساعد على تحسين وضعية الجلوس لتقليل الإجهاد والآلام في الظهر، بالإضافة إلى ذلك، تساهم الفتحات الموجودة في بعض الحوامل في تحسين تدفق الهواء تحت الجهاز، مما يقلل من حرارته ويعزز أداءه.
            </p>
          </div>
        </FeatureDropdown>


        <FeatureDropdown
          title="الأسئلة الأكثر شيوعًا"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-[85%]">
            <FeatureDropdown
              title="هل يمكن ثني حامل اللابتوب؟"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <div className="w-screen">
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">يعتمد ذلك على نوع الحامل؛ فهناك حوامل قابلة للطي والتعديل، وأخرى ثابتة وغير قابلة للثني.</p>
                </div>
              </div>
            </FeatureDropdown>

            <FeatureDropdown
              title="ما هي فوائد حامل اللابتوب؟"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">من أهم فوائد حامل اللابتوب: تحسين وضعية الجلوس لتجنب آلام الظهر، وتقليل درجة حرارة الجهاز من خلال الفتحات التي تسمح بتدفق الهواء.</p>
              </div>
            </FeatureDropdown>

          </div>
        </FeatureDropdown>


        <FeatureDropdown
          title="المنتجات"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">يوجد العديد من أنواع حاملات اللابتوب التي تأتي بميزات إضافية، مثل المراوح لتبريد الجهاز، فيما يلي سيتم ذكر ثلاثة أنواع مع روابط تسويقية لشرائها من أمازون.</p>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown
              title="حامل لابتوب إكس باور"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-[85%]">
                <FeatureDropdown
                  title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <img src={xpower} alt="" />
                </FeatureDropdown>

                <div className="w-screen">
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>التقييم:</b> 4.3 (197)
                  </p>
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>السعر:</b> 22 ريال
                  </p>
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>ملاحظة</b>: هذا المنتج من مختارات أمازون.
                  </p>
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>المميزات:</b>
                  </p>
                  <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                    <li>موزع للحرارة ومضاد للانزلاق.</li>
                    <li>مصنوع من سبيكة ألومنيوم عالية الجودة.</li>
                    <li>ستة زوايا لتعديل ارتفاع اللابتوب.</li>
                    <li>حجم محمول وصغير وقابل للطي</li>
                    <li>متوافق مع اللابتوب والأجهزة اللوحية ذات الشاشات الكبيرة.</li>
                    <li>إمكانية الإرجاع خلال 15 يومًا.</li>
                    <li>شراء:</li>
                  </ul>
                  <div className="w-[85%]">
                    <a href="https://amzn.to/3TPU3aK">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>


            <FeatureDropdown
              title="حامل لابتوب SZ-1"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-[85%]">
                <FeatureDropdown
                  title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <img src={sz1} alt="" />
                </FeatureDropdown>

                <div className="w-screen">
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>التقييم:</b> 4.1 (77)
                  </p>
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>السعر:</b> 95 ريال
                  </p>
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>ملاحظة</b>: هذا المنتج من مختارات أمازون.
                  </p>
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>المميزات:</b>
                  </p>
                  <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                    <li>وجود 4 منافذ usb مع مصباح صغير ومروحة صغيرة.</li>
                    <li>تعدد الوظائف، يمكن تعديل الزاوية من درجة 0 إلى 37 مما يتيح استخدامه في أشياء أكثر من فقط حمل اللابتوب.</li>
                    <li>حجم كبير يتيح لك وضع أشياء مختلفة مع اللابتوب، بالإضافة إلى وجود درج صغير لوضع الأشياء.</li>
                    <li>مصنوع من خشب ليفي متوسط الكثافة، وهو مقاوم للرطوبة والخدش ودرجات الحرارة.</li>
                    <li>إمكانية الإرجاع خلال 15 يومًا.</li>
                    <li>شراء:</li>
                  </ul>
                  <div className="w-[85%]">
                    <a href="https://amzn.to/3N8OfVR">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>

            <FeatureDropdown
              title="حامل لابتوب ساوند دانس"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
            >
              <div className="w-[85%]">
                <FeatureDropdown
                  title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
                >
                  <img src={sonddance} alt="" />
                </FeatureDropdown>

                <div className="w-screen">
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>التقييم:</b> 4.7 (5,318)
                  </p>
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>السعر:</b> 186 ريال
                  </p>
                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>المميزات:</b>
                  </p>
                  <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
                    <li>مناسب مع جميع اللابتوبات من مقاس 10-17.3 إنش.</li>
                    <li>يمكنك تعديل الارتفاع حسب احتياجك، ولكن لا يمكن تعديل الزاوية.</li>
                    <li>تصميم مناسب لرفع اللابتوب إلى مستوى العين.</li>
                    <li>مصنوع من سبائك الألمونيوم التي تساعد على تبديد الحرارة، وأيضا توجد مساحة بالأسفل لدخول الهواء للتبريد.</li>
                    <li>يحتوي على وسائد سيليكون للحفاظ على اللابتوب من الانزلاق والخدوش.</li>
                    <li>إمكانية الإرجاع خلال 15 يومًا.</li>
                    <li>شراء:</li>
                  </ul>
                  <div className="w-[85%]">
                    <a href="https://amzn.to/4dt3y6B">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>
          </div>
        </FeatureDropdown>

      </div>

    </div>
  );
}
