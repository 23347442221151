// visitorsData.ts

// Import images
import bag from '../assets/bage.svg';
import web from '../assets/web.svg';
import paper from '../assets/papers.svg';
import infos from '../assets/infos.svg';
import { articles } from '../data/sectionData';
import { categories } from '../data/categories';

// Define an interface for the visitor data
interface VisitorData {
  title: string;
  howMany: string;
  image: string;
}

// Create an array of visitor data
export const visitorsData: VisitorData[] = [
  {
    title: "مشتري من الموقع",
    howMany: "0",
    image: bag,
  },
  {
    title: "زائر للموقع",
    howMany: "0",
    image: web,
  },
  {
    title: "تقرير على الموقع",
    howMany: String(articles.length),
    // howMany:'10',
    image: paper,
  },
  {
    title: "عدد الأقسام",
    howMany: String(categories.length-1),
    image: infos,
  },
];
